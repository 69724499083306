@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
    font-family: 'Archivo', sans-serif !important;
    font-size: 14px;
}

#mainNav {
    height: 100px;
    background-color: #69d0bb;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 1.1em;
    font-family:"Open Sans", sans-serif;
    font-size:16px;
    img {
        width: 90%;
        max-width: 150px;
    }
    a {
        color: #fff;
        margin-right: 20px;
        &:hover {
            color: #efefef;
        }
    }
}

main {
    max-width: 1200px;
    margin: auto;

    .shape-background {
        position: fixed;
        opacity: 0.02;
    }

    .mobile-expander {
        display: none;
    }

    aside {
        padding: 20px 10px;
        a {
            color: inherit;
            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        .step-block {
            border-left: 2px solid #f1e075;
            padding: 15px 0 15px 20px;
            position: relative;
            &:before {
                content: '';
                width: 1.5em;
                height: 1.5em;
                background-color: white;
                position: absolute;
                left: -13px;
                top: 35px;
                border-radius: 100px;
                border: 1px solid #f1e075;
                transition: all 0.4s;
            }
            h5 {
                font-size: 1.1em;
                font-weight: 400;
            }
            h6 {
                font-weight: 400;
            }
            &.active {
                &:before {
                    background-color: #f1e075;
                }
                &:hover:before {
                    background-color: #f1e075;
                    transition: all 0.4s;
                }
            }
            &:hover:before {
                background-color: white;
                box-shadow: 0 0 0 7px #f1e075;
                width: 1em;
                height: 1em;
                left: -10px;
                top: 38px;
                transition: all 0.4s;
            }
        }
    }
    .article {
        text-align: justify;
        h1 {
            color: #17a2b8;
            font-size: 2.25em;
        }
        blockquote {
            font-style: italic;
            padding-left: 30px;
            white-space: break-spaces;
        }
        p {
            a {
                color: #17a2b8;
                font-weight: 500;
            }
        }
        .img-container {
            img {
                transition: .3s ease-in-out;
                max-width: 80%;
                &:hover {
                    transform: scale(1.08);
                }
            }
        }
        .exercise-box {
            border: 2px solid #17a2b8 ;
            border-radius: 30px;
            padding: 25px;
            h4 {
                color: #17a2b8;
            }
            hr {
                background: linear-gradient(to right, rgba(255,255,255, 0), #17a2b8 , rgba(255,255,255,0));
                height: 1px;
            }
            .submit-row {
                text-align: center;
                span, button {
                    margin: auto;
                }
                span {
                    color: #17a2b8;
                    font-weight: bold;
                    display: block;
                }
            }
        }
        .form-box {
            margin: 50px -15px;
            color: #17a2b8;
            padding: 50px 20%;
            border-radius: 50px;
            border: 2px solid #17a2b8;
            h3 {
                color: #17a2b8;
            }
            h6 {
                font-size: 1.2em;
            }
            textarea {
                outline: none;
                resize: none;
                width: 100%;
                height: 40px;
                overflow: hidden;
                border: 1px solid #17a2b8;
                padding: 7px 10px;
                color: #17a2b8;
                background-color: transparent;
                border-radius: 10px;
                &::placeholder {
                    color: #9d9d9d;
                }
                &:hover, &:focus {
                    &::placeholder {
                        color: #fff;
                    }
                }
            }
            .answers {
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                display: grid;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                div {
                    margin: 5px 0;
                    padding: 10px;
                    &:hover {
                        box-shadow: 0px 0px 10px #17a2b8;
                    }
                    i, span {
                        display: block;
                    }
                    i {
                        border: 1px solid #17a2b8;
                        width: 40px;
                        height: 40px;
                        margin: auto;
                        text-align: center;
                        border-radius: 100px;
                        color: #17a2b8;
                        line-height: 40px;
                        font-size: 20px;
                    }
                    span {
                        color: #17a2b8;
                        font-weight: 400;
                        text-align: center;
                        margin-top: 10px;
                    }
                    &.selected {
                        background-color: #17a2b8;
                        i, span {
                            color: #fff;
                            border-color: #fff;
                        }
                    }
                }
            }
            .save-box {
                text-align: center;
                padding-top: 30px;
            }
        }
    }
}

.media {
    background-color: #f1e075;
    color:black;
    padding: 20px 0;
    a {
        color: #000000;
    }
    .social .element {
        cursor: pointer;
    }
    .contact {
        input, textarea {
            background-color: transparent;
            width: 100%;
            outline: none;
            border: none;
            border-bottom: 1px solid #000000 !important;
            resize: none;
        }
    }
}

footer {
    background-color: #323232;
    color: #fff;
    padding: 10px 0;
    font-size: 1em;
    div {
        max-width: 1200px;
    }
    a {
        color: #fff;
        margin: 0 20px;
        &:hover {
            color: #bebebe;
            text-decoration: none;
        }
    }
}

.toolbox {
    position: fixed;
    bottom: 0;
    left: 75px;
    background-color: #69d0bb;
    color: #fff;
    padding: 15px 15px 10px 10px;
    border-radius: 0 10px 0 0;
    z-index: 25;

    svg {
        margin: 0 10px;
        cursor: pointer;
    }
}

.toolbox-alert {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #cdb100;
    color: #fff;
    padding: 15px 15px 10px 10px;
    z-index: 25;

    img {
        width: 30px;
        height: 30px;
        margin: 0 10px;
        cursor: pointer;
    }
}

.comfort-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 99999;
    display: flex;
    background-color: #00000029;
    div {
        background-color: #cdb100;
        max-width: 1000px;
        margin: auto;
        padding: 20px;
        border-radius: 20px;
        color: white;
        font-size: 16px;
    }
}

button {
    &.main, &.swal-button {
        background-color: #17a2b8;
        color: #fff;
        padding: 10px 30px;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: none !important;
        margin: 3px 5px;
        font-family: 'Archivo', sans-serif !important;
        &:hover, &:active, &:focus {
            background-color: #2a8c9c !important;
            border: 1px solid #fff;
        }
    }
    &.small {
        height: 30px;
        padding: 0 20px;
        font-size: 14px;
    }
}

.waiting-box {
    position: absolute;
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    background-color: rgba(0, 0, 0, .2)
}

.waiting-nested {
    position: fixed;
    width: 100vw;
    height: 100vh;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2)
}

.waiting-inline {
    margin: 30px 0;
}

.swal-text {
    color: #17a2b8;
    text-align: justify;
}

.swal-footer {
    text-align: center;
}

.checkbox {
    margin-right: 30px;
    input {
        display: none;
        vertical-align: center;
    }
    label {
        cursor: pointer;
        vertical-align: middle;
        display: inline-block;
        position: relative;
        &:before {
            content: "";
            display: inline-block;
            width: 30px;
            height: 30px;
            box-shadow: inset 0px 0px 0px 4px rgba(226, 226, 226, 1);
            border-radius: 20%;
            vertical-align: middle;
            transition: box-shadow cubic-bezier(0.27, 0.92, 0.89, 1.12) 0.2s;
            margin-right: 10px;
        }
        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            border-radius: 10%;
            width: 30px;
            height: 30px;
            top: 10px;
            left: 0;
            opacity: 0;
            transition: opacity cubic-bezier(0.27, 0.92, 0.89, 1.12) 0.2s;
            background: radial-gradient(
                rgba(23, 162, 184, 0.9),
                rgba(23, 162, 184, 0.6),
                rgba(23, 162, 184, 0.3),
                rgba(23, 162, 184, 0.05),
                rgba(23, 162, 184, 0)
            );
        }
    }
}

.checkbox input:checked + label:after {
    opacity: 0.7;
}
.checkbox label:active,
.checkbox label:focus {
    transform: scale(0.95);
}
.checkbox input:checked + label {
    animation: checkBounce linear 0.3s;
}
.checkbox input:not(:checked) + label {
    animation: uncheckBounce linear 0.3s;
}
.checkbox input:checked + label:before {
    transition: box-shadow cubic-bezier(0.27, 0.92, 0.89, 1.12) 0.2s;
}
.checkbox input:checked + label:before {
    box-shadow: inset 0px 0px 0px 18px rgba(23, 162, 184, 1);
}
.checkbox.bad input:checked + label:before {
    box-shadow: inset 0px 0px 0px 18px #eb5f5f;
}
.checkbox.correct input:checked + label:before {
    box-shadow: inset 0px 0px 0px 18px #69d0bb;
}
.checkbox label:active:before,
.checkbox label:focus:before {
    box-shadow: inset 0px 0px 0px 7px rgba(226, 226, 226, 1);
    transition: box-shadow cubic-bezier(0.27, 0.92, 0.89, 1.12) 0.1s;
}
.checkbox input:before + label:active:before,
.checkbox input:checked + label:focus:before {
    box-shadow: inset 0px 0px 0px 21px rgba(255, 153, 51, 1);
    transition: box-shadow cubic-bezier(0.27, 0.92, 0.89, 1.12) 0.1s;
}

.mpx-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important;
}

.modal-dialog {
    max-width: 80vw !important;
    .modal-content {
        border-radius: 50px !important;
        overflow: hidden;
        border: 3px solid #17a2b8 !important;
        .modal-body {
            min-height: 70vh;
            background: url(/images/avocado.jpg) no-repeat center center fixed; 
            -webkit-background-size: cover;
            -moz-background-size: cover;
            -o-background-size: cover;
            background-size: cover;
            .ball {
                width: 40px;
                top: 0;
                left: 0;
                position: absolute;
                svg {
                    color: #ede7c0;
                }
            }
        }
    }
} 

@keyframes checkBounce {
    0% {
        transform: scale(0.95);
    }
    50% {
       transform: scale(1.1);
    }
    75% {
       transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes uncheckBounce {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.1);
    }
    75% {
        transform: scale(0.95);
    }
    100% {
        transform: scale(1);
    }
}

.jargame {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    z-index: 30;
    .jargame-box {
        width: 50%;
        margin: auto;
        height: 80%;
        margin-top: 5%;
        background-color: #fdfdfd;
        border-radius: 30px;
        border: 2px solid #17a2b8;
    }
}

.col-sm-6 {
    width: 50% !important;
}

.col-sm-5 {
    width: 41% !important;
}

.col-sm-2 {
    width: 16% !important;
}

.col-sm-3 {
    width: 25% !important;
}

p.title {
    font-size: 1.4rem;
}


.mpt {
    padding-top:100px !important;
    margin-top: -90px !important;
}

.rhap_container {
    margin: 20px 0;
}

.wspw {
    white-space: pre-wrap;
}

.sketch-picker {
    position: absolute;
    z-index: 9999;
}

.extension-link {
    cursor: pointer;
    color: #17a2b8;
    font-weight: bold;
}

.mainTextColor {
    color: #17a2b8 !important;
}

.logotypes {
    max-width: 1000px;
    text-align: center;
    margin: 20px auto !important;
    img {
        max-width: 100%;
        max-height: 60px;
    }
}

.text-last-center {
    text-align-last: center;
}

@media (max-width: 990px) {

    .navbar-nav {
        position: fixed;
        left: 0;
        width: 100%;
        background-color: #69d0bb;
    }
    #mainNav {
        padding: 0;
        .container {
            background-color: #69d0bb;
            padding: 0 20px;
            text-align: center;
        }
    }

    aside {
        padding-top: 0 !important;
        &.hide {
            display: none;
        }
    }

    .mobile-expander {
        display: block !important;
        background-color: #69d0bb;
        margin: 0 -25px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        padding: 10px 0;
        border-top: 1px solid white;
    }

    main .article {
        .exercise-box {
            padding: 10px;
        }
        blockquote {
            padding-left: 10px;
        }
    }

    div.col {
        padding: 0;
    }

    .media {
        div {
            text-align: center !important;
            display: block;
        }
        .row {
            margin-top: 30px;
        }
        .about span {
            display: block;
        }
        h6 {
            font-size: 1.5em;
        }
    }

    footer {
        span {
            display: block;
        }
        span, div {
            text-align: center !important;
        }
    }

    .form-box {
        padding: 30px 10px !important;
        h6 {
            font-size: 1rem !important;
        }
        .answers {
            grid-template-columns: 1fr !important;
            div {
                text-align: left;
                i, span {
                    display: inline-block !important;
                    margin: 0 5px;
                }
            }
        }
        textarea {
            height: 60px !important;
        }
    }

    .mpx-5 {
        padding-right: 0!important;
        padding-left: 0!important;
    }
    .modal-dialog {
        max-width: 90vw !important;
        margin: auto !important;
    }

    .col-sm-6 {
        width: 50% !important;
    }

    .jargame {
        height: calc(100vh - 70px);
        top: 70px;
        .jargame-box {
            width: 100%;
            height: 100%;
            margin: 0;
        }
    }
}



.react-resizable {
    position: relative;
  }
  .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
    background-position: bottom right;
    padding: 0 3px 3px 0;
  }
  .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg);
  }
  .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize;
  }
  .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg);
  }
  .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg);
  }
  .react-resizable-handle-w,
  .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize;
  }
  .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg);
  }
  .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg);
  }
  .react-resizable-handle-n,
  .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize;
  }
  .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg);
  }
  .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg);
  }