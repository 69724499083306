.mtable {
    width: 100%;
    tr {
        width: 100%;
        td {
            width: 100%;
            min-height: 30px;
            height: 30px;
        }
        .element {
            position: relative;
            svg {
                display: none;
                cursor: pointer;
                &.btn1 {                    
                    position: absolute;
                    right: 30px;
                }
                &.btn2 {                    
                    position: absolute;
                    right: 0;
                }
            }
            &:hover {
                svg {
                    display: inline;
                }
            }
        }
    }
    .bordered {
        padding: 5px;
        border: 1px solid #17a2b8;
        border-radius: 5px;
        outline: none;
    }
    .ediv {
        width: 100%;
        display: inline-block;
        &:before {
            content: '•';
            left: -10px;
            position: absolute;
        }
        &:focus {
            padding: 5px;
            border: 1px solid #17a2b8;
            border-radius: 5px;
            outline: none;
        }
    }
}

.addBtn {
    background-color: transparent !important;
    color: black !important;
    font-size: 13px !important;
    margin: 10px !important;
    border: 1px solid #17a2b8 !important;
    min-height: 40px !important;
    &:hover {
        background-color: #17a2b8 !important;
    }
}

.imagePlace div {
    border: 1px solid #17a2b8;
    border-radius: 20px;
    min-height: 200px;
    width: 95%;
}