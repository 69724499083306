
.bad {
    color: #ff0000;
    font-weight: bold;
    border: 1px dotted red;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
}

.correct {
    color: #17a2b8;
    font-weight: bold;
    border: 1px dotted #17a2b8;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
}