.exerciseBox3 {
    .modalGame {
        border: 11.2px solid #17a2b8;
        border-radius: 100px;
        width: 200px;
        height: 200px;
        color: #17a2b8;
        padding: 20px;
        cursor: pointer;
        &:hover {
            color: #20d1ec;
            border-color: #20d1ec;
        }
    }
    .control {
        border: 11.2px solid #17a2b8;
        border-radius: 50%;
        margin: 20px;
        padding: 28px;
        width: 112px;
        height: 112px;
        font-size: 0;
        white-space: nowrap;
        text-align: center;
        cursor: pointer;
    }
    .control,
    .control .left,
    .control .right,
    .control:before {
        display: inline-block;
        vertical-align: middle;
        transition: border 0.4s, width 0.4s, height 0.4s, margin 0.4s;
        transition-tiomig-function: cubic-bezier(1, 0, 0, 1);
        box-sizing: unset;
    }
    .control:before {
    content: "";
        height: 112px;
        box-sizing: unset;
    }
    .control.pause .left,
    .control.pause .right {
        margin: 0;
        border-left: 36.96px solid #17a2b8;
        border-top: 0 solid transparent;
        border-bottom: 0 solid transparent;
        height: 96.992px;
    }
    .control.pause .left {
        border-right: 22.4px solid transparent;
    }
    .control.play .left {
        margin-left: 18.66666667px;
        border-left: 48.496px solid #17a2b8;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-right: 0px solid transparent;
        height: 56px;
    }
    .control.play .right {
        margin: 0;
        border-left: 48.496px solid #17a2b8;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        height: 0px;
    }
    .control:hover {
        border-color: #20d1ec;
    }
    .control:hover .left,
    .control:hover .right {
        border-left-color: #20d1ec;
    }
}