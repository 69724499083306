.blockRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    .blockCol {
        padding: 5px;
        height: 100%;
        position: relative;
        textarea {
            background-color: #69d0bb;
            height: 100%;
            text-align: center;
            padding: 7px;
            border-radius: 10px;
            width: 100%;
            resize: none;
            outline: none;
            &:focus {
                border: 2px solid #17a2b8;
            }
        }
        svg {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #2f8d2f;
            display: none;
            cursor: pointer;
        }
        &:hover svg {
            display: block;
        }
    }

    .blockColNew {
        padding: 5px;
        height: 100%;
        position: relative;
        .mdiv {
            background-color: #69d0bb;
            height: 100%;
            text-align: center;
            padding: 7px;
            border-radius: 10px;
            width: 100%;
            resize: none;
            outline: none;
            padding-top: 6%;
            border: 1px solid rgb(118, 118, 118);;
            svg {                
                color: #2f8d2f;
                cursor: pointer;
            }
        }
    }
}

.finished {
    color: #17a2b8;
    font-size: 15px;
    padding: 20px;
    text-align: justify;
    border: 1px solid #17a2b8;
    border-radius: 15px;
    font-weight: 700;
}


.timetable {
    .box1 {
        border: 1px solid #adadad;
        height: 100%;
        border-radius: 10px;
    }
    .title1 {
        border-bottom: 1px solid #adadad;
        font-weight: bold;
        text-align: center;
    }

    .workarea {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        .row {
            min-height: 30px;
            border-top: 1px dotted #adadad;
            display: flex;
        }    
    }

    .workhours {        
        width: 98% !important;
        position: absolute;
        margin-left: 1%;
        border-radius: 5px;
        display: table;
    }
    .workhours2 {
        background-color: #fbff9d;
    }

    .dragIcon {
        position: absolute;
        bottom: 0;
        right: 22px;
        cursor: pointer;
        transform: translate(0px, 0px) !important;
    }
}

.w50 {
    max-width: 50%;
    width: 50%;
    float: left;
    text-align: center;
    font-weight: bold;
    margin: 20px 0;
}

.hidden {
    display: none !important;
}

.noBorderBottom td {
    border-bottom: none !important;
}

.noBorderTop td {
    border-top: none !important;
}

.main {
    font-weight: bold;
}
.small {
    font-size: 0.8em;
}

.details div {
    display: block;
}

.workplain svg {
    cursor: pointer;
}

.mhour {
    width: 20%;
    border-right: 1px solid #adadad;
}

.mblock {
    width: 80%;
}

.mhour, .mblock {
    float: left;
}

.workBlock {
    &.break {
        background-color: #ade6ff;
    }
    border: 1px solid #17a2b8;
    margin: 2px;
    border-radius: 5px;
    padding: 5px 10px;
    .hours {
        font-size: 80%;
    }
    .text {

    }
}

.mbg {
    background-color: yellow;
}