.exerciseBox6 {
    .notice {
        font: 16px 'Gloria Hallelujah', cursive !important; 
        border:0;
        border-radius:3px;
        background: linear-gradient(#F9EFAF, #F7E98D);
        box-shadow:0 4px 6px rgba(0,0,0,0.1);
        transition:box-shadow 0.5s ease;
        width: 100%;
        padding: 10px;
        overflow: hidden !important;
        text-align: left;
        resize: none;
    }

    svg {
        transition: 0.5s all;
    }

    svg.add {
        position: absolute;
        top: 9px;
        right: 13px;
        cursor: pointer;
        opacity: 0;
    }

    svg.remove, svg.trash {
        position: absolute;
        top: 9px;
        right: 60px;
        cursor: pointer;
        opacity: 0;
    }

    svg.pin {
        position: absolute;
        top: 3px;
        right: 13px;
    }

    .optionNotice:hover {
        svg {
            opacity: 1;
            transition: 0.5s all;
        }
    }
}

@media (max-width: 990px) {
    .exerciseBox6 {
        .optionNotice {
            text-align: center;
        }
        .notice {
            width: 90%;
            margin: auto;
        }

        svg.pin {
            top: 0px;
            right: 0px;
        }

        h5 {
            padding: 0 20px;
        }
    }
}