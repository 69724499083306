.exerciseBox2 {
    span.hint {
        svg {
            color: #17a2b8;
            margin: -5px 5px 0 5px;
        }
    }
    .alert {
        color: #ff0000;
        font-weight: bold;
    }
    .alertCorrect {
        color: #17a2b8;
        font-weight: bold;
    }
}