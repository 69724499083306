.notepadBox {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #69d0bb;
    color: #fff;
    padding: 15px 10px 10px 15px;
    border-radius: 10px 0 0 0;
    width: 30vw;
    height: 80vh;
    z-index: 30;
    transition: 1.5s all;
    transform: translate3d(calc(30vw - 80px), calc(80vh - 60px),0);
    svg {
        margin: 0 10px;
        cursor: pointer;
    }
    &.show {
        transform: translate3d(0, 0, 0);
        transition: 1.5s all;
    }

    .container {
        height: 100%;
        padding: 0;
    }

    .note-editor {
        background-color: #fff;
        height: 100%;
        color: #000;
        .ql-container {
            height: calc(100% - 66px);
        }
        .ql-color, .ql-background, .ql-align {
            svg {
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }
    }
}

@media (max-width: 990px) {
    .notepadBox {
        width: 100vw;
        height: calc(100vh - 160px);
        transform: translate3d(calc(100vw - 80px), calc(100vh - 220px),0);
    }
}