.container {
    max-width: 100px;
    .row {
        display: grid;
        grid-template-columns: 2fr 1fr 2fr;
        div {
            text-align: center;
        }
    }
    input {
        width: 30px;
        text-align: center;
    }
    svg {
        cursor: pointer;
        color: #17a2b8;
    }
}