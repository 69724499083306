.jarBox {
    text-align: center;
    position: relative;
    padding: 20px;
    height: 100%;
    width: 100%;
    h4 {
        color: #17a2b8;
    }
    .jarDrawn {
        position: absolute;
        left: 0;
        top: 40%;
        z-index: 20;
        width: 100%;
        div {
            text-align: center;
            border: 0;
            border-radius: 3px;
            background: linear-gradient(#F9EFAF, #F7E98D);
            font: 12px 'Gloria Hallelujah', cursive !important; 
            width: 0;
            overflow: hidden;
            margin: auto;
            transition: box-shadow 1s ease-in-out, width 2s;
        }
        button, h5 {
            margin: 30px auto;
        }
    }
    .box {
        margin: auto;
        display: block;
        margin-top: 3%;
        width: 20em;
        height: 40em;
        max-height: calc(100% - 50px);
        background: none;
        position: relative;
    }
    .jar {
        z-index: 2;
        position: absolute;
        width: 100%;
        height: 80%;
        background: #a8dfff;
        border-radius: 0% 0% 20% 20%;
        top: 10%;
        left: 0%;
        opacity: 0.6;
        box-shadow: inset 46px 13px 144px 0px rgba(211,211,211,0.81);
        cursor: pointer;
    }
    .top1 {
        background: #C1C1C1;
        z-index: 2;
        opacity: 0.6;
        width: 104%;
        border-radius: 10%;
        height: 2%;
        position: absolute;
        top: 8.5%;
        left: -2%;
        border: 2px solid #585858;
    }
    .top2 {
        background: #C1C1C1;
        z-index: 2;
        opacity: 0.6;
        width: 104%;
        border-radius: 15%;
        height: 3%;
        position: absolute;
        top: 6.2%;
        left: -2%;
        border: 2px solid #585858;
    }
    .top3 {
        background: #C1C1C1;
        z-index: 2;
        opacity: 0.6;
        width: 104%;
        border-radius: 15%;
        height: 3%;
        position: absolute;
        top: 4%;
        left: -2%;
        border: 2px solid #585858;
    }
    .leftHandle {
        z-index: 6;
        opacity: 0.6;
        position: absolute;
        width: 2%;
        height: 57%;
        border-radius: 0% 80% 80% 0%;
        background: #a8d3ee;
        top: 16%;
        left: 2%;
    }
    .rightHandle {
        z-index: 6;
        opacity: 0.6;
        position: absolute;
        width: 2%;
        height: 57%;
        border-radius: 80% 0% 0% 80%;
        background: #a8d3ee;
        top: 16%;
        right: 2%;  
    }
    .notes {
        position: absolute;
        width: 100%;
        height: 80%;
        top: 10%;
        left: 0%;
        overflow: hidden;
        border-radius: 0% 0% 20% 20%;
        border: 2px solid #585858;
    }
    textarea {
        position: absolute;
        font: 12px 'Gloria Hallelujah', cursive !important; 
        border:0;
        border-radius:3px;
        background: linear-gradient(#F9EFAF, #F7E98D);
        box-shadow:0 4px 6px rgba(0,0,0,0.1);
        transition:box-shadow 0.5s ease;
        width: 50%;
        height: 44px;
        padding: 10px;
        overflow: hidden !important;
        text-align: left;
        resize: none;
    }
}


@media (max-width: 990px) {
    .jarBox {
        .box {
            height: 60vh;
            width: 100%;
        }
    }
}