.exerciseBox12 {
    .content {
        white-space: pre-wrap;
    }
    .minput {
        height: 38px;
        width: 100%;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
        padding: 2px 8px;
        outline: none;
        &:focus {
            border: 2px solid #2684FF !important;
        }
    }
    .category {
        display: inline-block;
        width: 100%;
        margin: 0 10px;
        .inside {
            border: 1px solid #17a2b8;
            border-radius: 10px;
            overflow: hidden;
            display: inline-block;
            width: 100%;
            div {
                display: inline-block;
            }
            .name {
                padding: 0 10px;
                width: calc(100% - 50px);
            }
            .color {
                width: 50px;
                height: 100%;
                padding: 10px 0;
                cursor: pointer;
            }
            input {
                width: calc(100% - 25px);
            }
        }
        svg {
            cursor: pointer;
            margin: 0 5px;
            width: 15px;
        }
    }
    .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
    table {
        svg {
            cursor: pointer;
        }
        tr.noborder td {
            border-bottom: none;
            border-top: none;
            padding: 5px 3px;
            vertical-align: middle;
        }
        .notice {
            border-radius: 20px;
            padding: 10px 3px;
            cursor: pointer;
        }
        td {
            text-align: center;
        }
    }
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cacaca7a;
    z-index: 9999;
    text-align: center;
    padding-top: 10%;
    .container {
        background-color: #fff;
        border: 2px solid #17a2b8;
        border-radius: 15px;
        max-width: 600px;
        margin: auto;
        padding: 20px;
        text-align: center;
    }
}