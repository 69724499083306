.exerciseBox4 {
    .explanation {
        color: #17a2b8;
        border: 1px dotted #17a2b8;
        border-radius: 20px;
        padding: 20px;
        font-weight: bold;
    }
    .correctUnderline {
        border-bottom: 2px solid #69d0bb;
        padding-right: 10px;
    }
}