.mlabel {
    .minput {
        display: inline-block !important;
        width: calc(100% - 80px);
        border: 1px solid #17a2b8;
        border-radius: 5px;
        height: 30px;
        padding: 0 15px;
        &:focus {
            border: 2px solid #17a2b8;
            outline: none;
        }
    }
    width: 100%;
    svg {
        margin-left: 5px;
        cursor: pointer;
    }
}

.buttonAdd {
    margin-left: 40px !important;
}