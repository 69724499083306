.exerciseBox7 {
    .mspacing {
        white-space: pre-wrap;
    }
   .mspacing2 {
       p {
            white-space: pre-wrap;
            text-align: justify;
       }
   }
}