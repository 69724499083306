.exerciseBox8 {
    .mspacing, ol, li {
        white-space: pre-wrap;
    }
   .mspacing2 {
       p, ol, li {
            white-space: pre-wrap;
            text-align: justify;
       }
   }
}