.cover {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ffffff38;
    z-index: 99999;
    .box {
        width: 50vw;
        height: 80vh;
        background-color: #fff;
        border: 2px solid #17a2b8;
        border-radius: 20px;
        margin: auto;
        margin-top: 10vh;
        position: relative;
        overflow: hidden;
    }
    .content {
        overflow: auto;
        height: calc(100% - 75px);
        padding: 50px;
        text-align: justify;
    }
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        height: 75px;
        text-align: center;
        border-top: 1px solid #17a2b8;
    }
}

@media (max-width: 990px) {
    .cover {
        .box {
            width: 95vw;
            height: 80vh;
        }
        .content {
            padding: 20px;
        }
    }
}