.exerciseBox1 {
    td, th {
        text-align: center;
        vertical-align: middle !important;
        font-size: 0.9rem;
    }
    th {
        background-color: #17a2b8;
        color: #fff;
    }
    td {
        border: 1px solid #17a2b8 !important;
        span {
            display: block;
            border: 1px dotted #17a2b8;
            margin: 5px 0;
            padding: 3px;
            border-radius: 5px;
            cursor: pointer;
            &.checked {
                background-color: #f1e075;
                color: #000;
            }
            &:hover {
                background-color: #17a2b8;
                color: #fff;
            }
        }
    }
    tr {
        &.bad {
            background-color: #ffcbcb;
        }
        &.correct {
            background-color: #cbffcb;
        }
    }
}


@media (max-width: 990px) {
    .exerciseBox1 {
        th, td {
            padding: 0 !important;
        }
    }
}