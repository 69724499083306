.box {
    position: relative;
    img {
        width: 100%;
    }
    .koncentracja {
        left: 8%;
        top: 11%;
        width: 15%;
        height: 4%;      
    }
    .pamiec {
        left: 28%;
        top: 16%;
        width: 15%;
        height: 4%;   
    }
    .jakoscSnu {
        left: 14%;
        top: 28%;
        width: 15%;
        height: 4%;
    }
    .nastroj {
        left: 30%;
        top: 34%;
        width: 15%;
        height: 4%;
    }
    .swiadomeDoswiadczanie {
        left: 10%;
        top: 45%;
        width: 18%;
        height: 4%;
    }
    .tuiTeraz {
        left: 27%;
        top: 53%;
        width: 15%;
        height: 4%;
    }
    .mysliWydarzenia {
        left: 13%;
        top: 65%;
        width: 20%;
        height: 4%;
    }
    .stres {
        left: 72%;
        top: 11%;
        width: 15%;
        height: 4%;
    }
    .lek {
        left: 59%;
        top: 20%;
        width: 15%;
        height: 4%;
    }
    .depresja {
        left: 72%;
        top: 29%;
        width: 15%;
        height: 4%;
    }
    .zaburzeniaLekowe {
        left: 53%;
        top: 40%;
        width: 21%;
        height: 4%;
    }
    .rozpamietywaniePrzeszlosci {
        left: 68%;
        top: 51%;
        width: 20%;
        height: 4%;
    }
    .autopilot {
        left: 59%;
        top: 61%;
        width: 15%;
        height: 4%;
    }
    .zamartwianieSie {
        left: 68%;
        top: 71%;
        width: 19%;
        height: 4%;
    }
    .tooltipster {
        position: absolute;
        cursor: pointer;
        div {
            width: 100%;
            height: 100%;
        }
    }
}